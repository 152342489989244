import React from 'react';
import url from 'url';
import { string } from 'prop-types';
import { Modal } from '@andes/modal';
import Spinner from '@andes/spinner';

import PaymentData from './payment-data';
import Iframe from '../../../commons/iframe';
import withTracker from '../../../commons/with-tracker';

class PaymentDataDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.hideLoading = this.hideLoading.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onIframeLoad = this.onIframeLoad.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.state = {
      isOpenModal: false,
      showLoading: false,
      modalUrl: '',
      iframeUrl: '',
    };
  }

  /* istanbul ignore next */
  onMessage(type, data, container) {
    const modalTypes = ['home:modal-content:loading', 'home:modal-content:loaded'];
    const hasDataType = (type === data.type) || (modalTypes.indexOf(type) !== -1);
    const modalScrollSelector = document.querySelector('.andes-modal__scroll');

    if (hasDataType && data.type === 'home:modal-content:loading') {
      this.setState({ showLoading: true });
    }

    if (modalScrollSelector) {
      modalScrollSelector.scrollTop = 0;
    }

    if (hasDataType && data.height) {
      const messageHeight = parseInt(data.height, 10);
      container.style.height = `${messageHeight}px`;
    }

    if (hasDataType && data.postTitle) {
      const containerTitle = document.querySelector('.andes-modal__header .andes-modal__title');
      containerTitle.textContent = data.postTitle;
    }
  }

  /* istanbul ignore next */
  onIframeLoad(e) {
    const { iframeUrl } = this.state;
    const iframe = e.target;
    const hashUrl = url.parse(iframeUrl).hash;

    if (hashUrl && iframe.contentWindow?.document?.querySelector(hashUrl)) {
      const modalScroll = document.querySelector('.andes-modal__scroll');
      modalScroll.scrollTop = iframe.contentWindow.document?.querySelector(hashUrl).offsetTop;
    }

    this.hideLoading();
  }

  /* istanbul ignore next */
  openModal(e) {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      const modalUrl = '#show-modal';

      // Cambio la forma en la que se arma la URL para que tenga en cuenta los query params de la URL proveniente
      let iframeUrl = new URL(e.currentTarget.href);
      iframeUrl.searchParams.append('modal', 'true');
      iframeUrl = iframeUrl.toString();

      this.setState({
        isOpenModal: true,
        showLoading: true,
        modalUrl,
        iframeUrl,
        title: e.currentTarget.dataset.title,
      });
    }
  }

  hideModal() {
    this.setState({
      isOpenModal: false,
      showLoading: false,
    });
  }

  hideLoading() {
    this.setState({
      showLoading: false,
    });
  }

  render() {
    const {
      isOpenModal,
      title,
      modalUrl,
      showLoading,
      iframeUrl,
    } = this.state;

    const { text_close = null } = this.props;

    return (
      <PaymentData
        openModal={this.openModal}
        logosSize="large"
        {...this.props}
      >
        <Modal
          open={isOpenModal}
          onClose={this.hideModal}
          type="large"
          className="modal payment-data__modal"
          title={title}
          url={modalUrl}
          maxWidth={840}
          closeButtonSrLabel={text_close}
        >
          <Spinner
            modifier="fullscreen"
            size="large"
            show={showLoading}
          />
          {modalUrl
            && (
              <Iframe
                title={title}
                src={iframeUrl}
                onLoad={this.onIframeLoad}
                onMessage={this.onMessage}
                scrolling="no"
              />
            )}
        </Modal>
      </PaymentData>
    );
  }
}

PaymentDataDesktop.propTypes = {
  text_close: string,
};

export default withTracker(PaymentDataDesktop);

